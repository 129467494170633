<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: false}"></v-header>
        </div>
        <div class="article">
            <p style="text-align: center;">
                <span style="font-size: 20px;"><strong>彩友圈管理规范</strong></span>
            </p>
            <p>
                好劲抽致力于提供一个由用户创造高质量内容的彩票社区。为了保护用户创造的内容、维护良好的社区氛围，好劲抽团队和好劲抽授权的社区管理员将依据<span style="color: rgb(0, 112, 192);">《好劲抽服务协议》，《好劲抽软件许可及服务协议》</span>和本规范中的条款对好劲抽的用户和发布在彩友圈的内容进行管理。采取的措施包括但不限干更改或删除用户发布的内容、暂停或终止用户使用好劲抽账号的权利。
            </p>
            <p>
                <br/>
            </p>
            <p>
                所有有意的降低内容质量、伤害社区氛围的行为(下称「破坏」)都是不能容忍的。多数情况下社区中的其他用户会优先选择对破坏行为做出劝诫、建议和引导，好劲抽团队也会先发出警告并给予用户改正自己不当行为的机会，但不表示好劲抽团队在采取任何措施前必须首先发出警告。
            </p>
            <p>
                除非您已阅读并接受本规范所有条款，否则您无权使用彩友圈。您对好劲抽的任何购买或接受赠与等获取行为及登录、查看等任何使用行为即视为您已阅读并同意本规范的约束。
            </p>
            <p>
                <br/>
            </p>
            <p>
                一、温馨提示
            </p>
            <p>
                1.<span style="color: rgb(255, 0, 0);">注意个人隐私</span>，务必不要泄露个人隐私，以免造成不必要的损失:
            </p>
            <p>
                2.请勿向他人索取联系方式(QQ号、QQ群号、微信号、手机号等)，以免造成他人不必要的麻烦，多次询问将被封号处理:
            </p>
            <p>
                3.<span style="color: rgb(255, 0, 0);">谨防骗子，任何涉及红包、钱财等行为需要谨慎</span>;
            </p>
            <p>
                4.码无绝对，对于任何声称包中奖、绝对赚钱等蛊惑行为可以及时举报:
            </p>
            <p>
                5.支持公彩，打击私彩，任何在线售卖彩票的形式，都属于违法行为，请不要相信，以免造成不必要的财产损失。一经发现将会删除所有内容并且封号处理。
            </p>
            <p>
                6.希望VIP会员用户支持好劲抽，<span style="color: rgb(255, 0, 0);">不要透露VIP数据</span>；并且也希望普通用户尊重好劲抽的知识产权及劳动成果，<span style="color: rgb(255, 0, 0);">请勿询问任何关于好劲抽的 VIP数据</span>。
            </p>
            <p>
                二、违规行为界定(违规将被封号)
            </p>
            <p>
                平台对违规者，处理非常严格，若被封号，所有的功能都会受到影响，拥有的太阳将会被冻结，请知悉。以下是违规行为
            </p>
            <p>
                1.发布、传送、传播、储存<span style="color: rgb(255, 0, 0);">违反国家法律法规禁止的内容</span>
            </p>
            <p>
                2.发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容;
            </p>
            <p>
                3.<span style="color: rgb(255, 0, 0);">涉及他人隐私、个人信息或资料的</span>:索取QQ号、群号、微信号、手机号等联系方式，发布他人隐私等；
            </p>
            <p>
                4.<span style="color: rgb(255, 0, 0);">发布联系方式</span>(QQ号、QQ群号、微信号、手机号等)以及变相发布(如用大写的汉字替代、头像发布、个人名称发布)的。
            </p>
            <p>
                5.发表、传送、传播骚扰、广告信息及垃圾信息或含有任何性或性暗示的
            </p>
            <p>
                6.<span style="color: rgb(255, 0, 0);">不友善行为</span>:不尊重彩友圈用户及其所贡献内容的行为，主要表现为:
            </p>
            <p>
                (1)轻蔑;贬低、轻视他人及其劳动成果；
            </p>
            <p>
                (2)诽谤:捏造、散布虚假事实，损害他人名誉;
            </p>
            <p>
                (3)嘲讽:以比喻、夸张、侮辱性的手法对他人或其行为进行揭露或描述，以此来激怒他人;
            </p>
            <p>
                (4)挑衅:以不友好的方式激怒他人，意图使对方对自己的言论作出回应，蓄意制造事端;
            </p>
            <p>
                (5)羞辱:贬低他人的能力、行为、生理或身份特征，让对方难堪;
            </p>
            <p>
                (6)谩骂:以不文明的语言对他人进行负面评价;
            </p>
            <p>
                (7)歧视:针对他人的民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类的攻击;
            </p>
            <p>
                (8)威胁:许诺以不良的后果来迫使他人服从自己的意志;
            </p>
            <p>
                7.发布垃圾信息:以赢利为目的，发布影响用户体验、<span style="color: rgb(255, 0, 0);">扰乱好劲抽社区秩序</span>的信息或相关行为，主要表现为:
            </p>
            <p>
                (1)重复发布相似内容(包括但不限于提问及其补充说明，话题及其描述，回答，评论，收藏夹及其补充说明，文章，私信)
            </p>
            <p>
                (2)拥有多个帐号，或者与多个帐号合作谋求不正当曝光
            </p>
            <p>
                (3)买卖帐号、关注、赞同，或者其他干扰社区秩序的交易
            </p>
            <p>
                8.<span style="color: rgb(255, 0, 0);">恶意行为</span>，主要表现为:
            </p>
            <p>
                (1)清空或删除有效内容，添加无关信息.破坏内容结构等降低公共编辑内容质量的编辑;
            </p>
            <p>
                (2)冒充他人，通过头像、用户名等个人信息暗示自己与他人或机构相等同或有关联;
            </p>
            <p>
                (3)骚扰他人，以评论、@他人、私信等方式对他人反复发送重复或者相似的诉求;
            </p>
            <p>
                (4)发布含有钓鱼网站、木马、病毒网站链接及相关内容;
            </p>
            <p>
                (5)发布含有潜在危险、窃取用户隐私等相关内容;
            </p>
            <p>
                (6)以赢利为目的，蛊惑他人；如:发布没有事实依据的中奖信息，换取他人的有偿跟单等行为。
            </p>
            <p>
                9.<span style="color: rgb(255, 0, 0);">每位用户只能拥有一个账号</span>，严令禁止使用多个账号上大师榜，一旦发现拥有多个账户，或者拥有多个大师账号，将全部封号处理。
            </p>
            <p>
                10.其他违反法律法规、政策及公序良俗、社会公德或<span style="color: rgb(255, 0, 0);">干扰彩友圈正常运营</span>和侵犯其他用户或第三方合法权益内容的信息。
            </p>
            <p>
                11.图纸帖子不允许发表带有连续命中、xx期中xx、以及任何相关等标注字样，这些属于诱导性行为帖子，警告后仍然发表将被禁言处理
            </p>
            <p>
                三、被禁言/封号处理
            </p>
            <p>
                所有互动行为都将被终止，彩友圈评论将无法查看，太阳资金将会被冻结，若是达人、大师、周月榜，将一律取消其资格，等其他相关服务。
            </p>
            <p>
                四、遵守《互联网信息服务管理办法》
            </p>
            <p>
                遵守《互联网信息服务管理办法》，尤其是不得制作、复制、发布、传播含有下列内容的信息；
            </p>
            <p>
                1.反对宪法所确定的基本原则的；
            </p>
            <p>
                2.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;
            </p>
            <p>
                3.损害国家荣誉和利益的；
            </p>
            <p>
                4.煽动民族仇恨、民族歧视，破坏民族团结的;
            </p>
            <p>
                5.破坏国家宗教政策，宣扬邪教和封建迷信的;
            </p>
            <p>
                6.散布谣言，扰乱社会秩序，破坏社会稳定的;
            </p>
            <p>
                7.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;
            </p>
            <p>
                8.侮辱或者诽谤他人，侵害他人合法权益的；
            </p>
            <p>
                9.含有法律、行政法规禁止的其他内容的;
            </p>
            <p>
                五、其他疑问
            </p>
            <p>
                好劲抽可以随着社区管理经验的不断丰富，出于维护彩友圈社区氛围和秩序的目的，不断完善本规定。如果用户对好劲抽基于本规范做出的处理有异议，可以通过点击<span style="color: rgb(0, 112, 192);" @click="goPage('contact')">【联系我们】</span>进行反馈。
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    created(){
    }
}
</script>
<style lang="less" scoped>
    .container{
        .article{
            padding: 0 10px;
            font-size: 16px;
            h2{
                text-align: center;
            }
        }
    }
</style>